<template>
  <div
    class="form-group col-md-3"
    :style="{ display: isHidden(input) ? 'none' : 'block' }"
    v-if="
      ['text', 'number'].includes(
        getInputType(beForm[`attribute_${input}_value`])
      )
    "
  >
    <base-input
      :name="beForm[`attribute_${input}_value`].label"
      :vid="`attribute_${input}_value`"
      :label="beForm[`attribute_${input}_value`].label"
      v-model="inputVal[`attribute_${input}_value`]"
      :type="getInputType(beForm[`attribute_${input}_value`])"
      :rules="getRules(input)"
    />
  </div>
  <div
    class="form-group col-md-3"
    :style="{ display: isHidden(input) ? 'none' : 'block' }"
    v-else-if="
      ['decimal'].includes(getInputType(beForm[`attribute_${input}_value`]))
    "
  >
    <base-input
      :name="beForm[`attribute_${input}_value`].label"
      :vid="`attribute_${input}_value`"
      :label="beForm[`attribute_${input}_value`].label"
      v-model="inputVal[`attribute_${input}_value`]"
      type="text"
      :rules="getRules(input)"
    />
  </div>
  <div
    class="form-group col-md-3"
    :style="{ display: isHidden(input) ? 'none' : 'block' }"
    v-else-if="getInputType(beForm[`attribute_${input}_value`]) === 'textarea'"
  >
    <base-textarea
      :name="beForm[`attribute_${input}_value`].label"
      :vid="`attribute_${input}_value`"
      :label="beForm[`attribute_${input}_value`].label"
      v-model="inputVal[`attribute_${input}_value`]"
      :rules="getRules(input)"
      rows="4"
      maxrows="4"
    />
  </div>
  <div
    class="form-group col-md-3"
    :style="{ display: isHidden(input) ? 'none' : 'block' }"
    v-else-if="getInputType(beForm[`attribute_${input}_value`]) === 'select'"
  >
    <base-select
      :name="beForm[`attribute_${input}_value`].label"
      :vid="`attribute_${input}_value`"
      :label="beForm[`attribute_${input}_value`].label"
      v-model="inputVal[`attribute_${input}_value`]"
      :options="beForm[`attribute_${input}_value`].options"
      :rules="getRules(input)"
      :taggable="false"
      :multiple="false"
      :closeOnSelect="true"
    />
  </div>
  <div
    class="form-group col-md-3"
    :style="{ display: isHidden(input) ? 'none' : 'block' }"
    v-else-if="
      getInputType(beForm[`attribute_${input}_value`]) === 'datepicker'
    "
  >
    <base-datepicker
      :name="beForm[`attribute_${input}_value`].label"
      :vid="`attribute_${input}_value`"
      :label="beForm[`attribute_${input}_value`].label"
      v-model="inputVal[`attribute_${input}_value`]"
      :rules="getRules(input)"
    />
  </div>
  <div
    class="form-group col-md-3"
    :style="{ display: isHidden(input) ? 'none' : 'block' }"
    v-else-if="
      getInputType(beForm[`attribute_${input}_value`]) === 'timepicker'
    "
  >
    <base-timepicker
      :name="beForm[`attribute_${input}_value`].label"
      :vid="`attribute_${input}_value`"
      :label="beForm[`attribute_${input}_value`].label"
      v-model="inputVal[`attribute_${input}_value`]"
      :rules="getRules(input)"
    />
  </div>
  <div
    class="form-group col-md-3"
    :style="{ display: isHidden(input) ? 'none' : 'block' }"
    v-else-if="getInputType(beForm[`attribute_${input}_value`]) === 'radio'"
  >
    <base-select
      :name="beForm[`attribute_${input}_value`].label"
      :vid="`attribute_${input}_value`"
      :label="beForm[`attribute_${input}_value`].label"
      v-model="inputVal[`attribute_${input}_value`]"
      :options="[
        { text: 'SI', value: 'Y' },
        { text: 'NO', value: 'N' },
      ]"
      :rules="getRules(input)"
      :taggable="false"
      :multiple="false"
      :closeOnSelect="true"
    />
  </div>
</template>
<script>
// import { mapGetters } from "vuex";
import { getInputType } from "@/utils/forms";
import { prepareRules } from "@/utils/validation";
import BaseTextarea from "@/components/form/BaseTextarea";
import BaseSelect from "@/components/form/BaseSelect";
import BaseInput from "@/components/form/BaseInput";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseTimepicker from "@/components/form/BaseTimepicker";
export default {
  props: {
    value: undefined,
    customInputs: Array,
    beForm: {
      type: Object,
      default() {
        return {};
      },
    },
    beRules: {
      type: Object,
      default() {
        return {};
      },
    },
    input: {
      type: String,
    },
  },
  components: {
    BaseTextarea,
    BaseSelect,
    BaseInput,
    BaseDatepicker,
    BaseTimepicker,
  },
  methods: {
    getInputType,
    getRules(field) {
      let rules = {};
      if (this.beRules.length) {
        rules = prepareRules(this.beRules[field]);
      }
      return rules;
    },
    isHidden(attrCode) {
      let found = null;
      let cas = this.$store.state.auth.customAttributes;
      for (const v of Object.values(cas)) {
        found = v.find((e) => e.key == attrCode);
        if (found) break;
      }
      if (found) {
        return found.is_hidden === "Y" ? true : false; // era last_delete
      }
      return false;
    },
    // ...mapGetters("auth", ["customAttributes"]),
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
