<template>
  <div class="mx-1">
    <table-policies
      :filterOn="{ byRelations: ['byMandateCode'] }"
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      :onlyActions="['infomodal']"
      noInnerWidth
      noInputPaginator
    >
    </table-policies>
  </div>
</template>

<script>
import { toLocaleCurrency } from "@/utils/strings";
import DetailTabAutofetchTableMixin from "@/mixins/DetailTabAutofetchTableMixin";

export default {
  mixins: [DetailTabAutofetchTableMixin],
  name: "Policy",
  data() {
    return {
      filter: this.initFilter(),
      filterName: "filterPolicyDetail",
      repository: "insurance_policy",
      resource: "insurance_policies",
      tableRef: "PolicyTableRef",

      fields: [
        {
          key: "insurer.title",
          label: this.getDictionary("insurer_id", "insurance_policy"),
          sortable: true,
          sortKey: "byInsurer.title",
        },
        {
          key: "insurer.code",
          label: this.getDictionary("code", "insurer"),
          sortable: true,
          sortKey: "byInsurer.code",
        },
        {
          key: "status_policy.text",
          label: this.getDictionary("status_policy", "insurance_policy"),
          sortable: true,
          sortKey: "byAttribute.status_policy",
        },
        {
          key: "title",
          label: this.getDictionary("title", "insurance_policy"),
          sortable: true,
          sortKey: "byAttribute.title",
        },
        {
          key: "number",
          label: this.getDictionary("number", "insurance_policy"),
          sortable: true,
          sortKey: "byAttribute.number",
        },
        {
          key: "mandate_code.code",
          label: this.getDictionary("mandate_code", "insurance_policy"),
          sortable: true,
          sortKey: "byAttribute.mandate_code_id",
        },
        {
          key: "gross_premium",
          value: "gross_premium",
          label: this.getDictionary("gross_premium", "insurance_policy"),
          formatter: (value) => toLocaleCurrency(value),
          sortable: true,
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "gross_payment_splitting",
          value: (item) =>
            toLocaleCurrency(
              item.gross_premium *
                (12 / (parseInt(item.payment_splitting.value) || 12))
            ),
          label: this.getDictionary("gross_premium", "insurance_policy"),
          sortable: true,
          sortKey: "byAttribute.gross_premium",
          formatter: (value) => toLocaleCurrency(value),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "net_premium",
          label: "Netto annuo",
          sortable: true,
          sortKey: "byAttribute.net_premium",
          formatter: (value) => toLocaleCurrency(value),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "insurance_risk.risk_branch.title",
          label: this.getDictionary("title", "risk_branch"),
          sortable: true,
          sortKey: "byRiskBranch.title",
        },
        {
          key: "insurance_risk.risk_branch.risk_type.title",
          label: this.getDictionary("title", "insurance_risk"),
          sortable: true,
          sortKey: "byRiskType.title",
        },
        {
          key: "contraente",
          label: this.getDictionary("contractor"),
          sortable: true,
          sortKey: "byRegistry.title",
        },
      ],
    };
  },
  components: {
    TablePolicies: () => import("@/components/tables/Policies"),
  },
  props: {
    resourceId: Number,
  },
  methods: {
    initFilter() {
      let init = {
        byClaim: { id: this.resourceId },
      };
      return init;
    },
  },
};
</script>
