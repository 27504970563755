<template>
  <b-modal
    :id="id"
    size="lg"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
    @ok="close"
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="people"></b-icon>
        <span>Crea una {{ title }}</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="
          cancel();
          close();
        "
      >
        &times;
      </button>
    </template>
    <validation-observer ref="observer1" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="">
        <b-card header="Dati Generali" header-tag="header">
          <b-card-text>
            <b-row>
              <div class="form-group col-md-3">
                <base-input
                  :name="beFormCreate[rep].attribute_FULN_value.label"
                  vid="attribute_FULN_value"
                  :label="beFormCreate[rep].attribute_FULN_value.label"
                  v-model="inputVal[rep].attribute_FULN_value"
                  placeholder="Inserisci un cognome"
                  :rules="{ required: true }"
                />
              </div>
              <div class="form-group col-md-3" v-if="status_claimparty === 3">
                <base-input
                  :name="beFormCreate[rep].attribute_CREG_value.label"
                  vid="attribute_CREG_value"
                  :label="beFormCreate[rep].attribute_CREG_value.label"
                  v-model="inputVal[rep].attribute_CREG_value"
                  placeholder="Inserisci una PIVA"
                  :rules="{ required: true }"
                />
              </div>
              <div class="form-group col-md-3">
                <base-input
                  :name="beFormCreate[rep].attribute_NINO_value.label"
                  vid="attribute_NINO_value"
                  :label="beFormCreate[rep].attribute_NINO_value.label"
                  v-model="inputVal[rep].attribute_NINO_value"
                  textcase="upper"
                  placeholder="Inserisci un codice fiscale"
                  :rules="getRules('attribute_NINO_value', 'claim_party')"
                />
              </div>
              <div class="form-group col-md-3" v-if="status_claimparty === 1">
                <base-input
                  :name="beFormCreate[rep].attribute_CPINS_value.label"
                  vid="attribute_CPINS_value"
                  :label="beFormCreate[rep].attribute_CPINS_value.label"
                  v-model="inputVal[rep].attribute_CPINS_value"
                  placeholder="Inserisci una compagnia"
                />
              </div>
              <div class="form-group col-md-3" v-if="status_claimparty === 1">
                <base-input
                  :name="beFormCreate[rep].attribute_CPRGP_value.label"
                  vid="attribute_CPRGP_value"
                  :label="beFormCreate[rep].attribute_CPRGP_value.label"
                  v-model="inputVal[rep].attribute_CPRGP_value"
                  placeholder="Inserisci una targa"
                />
              </div>
              <div class="form-group col-md-3" v-if="status_claimparty === 1">
                <base-input
                  :name="beFormCreate[rep].attribute_CPVEH_value.label"
                  vid="attribute_CPVEH_value"
                  :label="beFormCreate[rep].attribute_CPVEH_value.label"
                  v-model="inputVal[rep].attribute_CPVEH_value"
                  placeholder="Inserisci un veicolo"
                />
              </div>
            </b-row>
          </b-card-text>
        </b-card>
        <address-book-form
          v-model="address_book_form"
          :beECForm="beFormCreate[addrBook]"
          :repository="addrBook"
        ></address-book-form>
        <correspondence-form
          v-model="correspondence_form"
          :beECForm="beFormCreate[cor]"
          :repository="cor"
        >
        </correspondence-form>
        <b-button
          @click="handleSubmit(save)"
          type="button"
          :disabled="invalid"
          variant="outline-lisaweb"
          size="sm"
          class="mt-2 float-right"
        >
          Salva
        </b-button>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import BaseInput from "@/components/form/BaseInput";
import FormMixin from "@/mixins/FormMixin";
import AddressBookForm from "@/components/form/AddressBookForm";
import CorrespondenceForm from "@/components/form/CorrespondenceForm";
import AddresBookCorrespondenceMixin from "@/mixins/AddressBookCorrespondenceMixin";
import { mapGetters } from "vuex";

export default {
  mixins: [FormMixin, AddresBookCorrespondenceMixin],
  data() {
    return {};
  },
  props: {
    id: {
      type: String,
      default: "createClaimPartyModal",
    },
    title: {
      type: String,
    },
    status_claimparty: {
      type: Number,
    },
    value: null,
    beRulesCreate: {
      type: Object,
      default() {
        return {};
      },
    },
    beFormCreate: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    BaseInput,
    AddressBookForm,
    CorrespondenceForm,
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    rep() {
      return "claim_party";
    },
    cor() {
      return "correspondence";
    },
    addrBook() {
      return "address_book";
    },
  },
  methods: {
    save() {
      this.$emit("save", this.inputVal);
    },
    close() {
      this.$emit("close", this.inputVal);
    },
    ...mapGetters("auth", [
      "countries",
      "provinces",
      "provinceByProvinceId",
      "countries",
      "provinces",
      "councils",
    ]),
  },
  beforeMount() {
    this.beRules["claim_party"] = this.beRulesCreate["claim_party"];
    this.beRules["address_book"] = this.beRulesCreate["address_book"];
    this.beRules["correspondence"] = this.beRulesCreate["correspondence"];
    this.form = this.inputVal;
    this.initCreateCorrespondencesForm();
  },
};
</script>
<style lang="scss" scoped></style>
