<template>
  <div class="mt-2">
    <!-- <div :style="[!noInnerWidth ? { width: width } : {}]"> -->
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div>
        <b-table
          :busy="isLoading"
          head-variant="light"
          :no-local-sorting="true"
          :items="items"
          :fields="getAllFields"
          :current-page="currentPage"
          per-page="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="height"
          no-border-collapse
          responsive
          show-empty
          empty-text="Non ci sono informazioni da mostrare"
          small
          @sort-changed="sortingChanged"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
          <template #cell(claim_number)="{ item }">
            {{
              joinValues([
                item.insurance_policy && item.insurance_policy.insurer
                  ? item.insurance_policy.insurer
                    ? item.insurance_policy.insurer.code
                    : null
                  : null,
                item.insurance_policy && item.insurance_policy.insurer
                  ? findAndGet(
                      $store.state.auth.mandate_codes,
                      "id",
                      item.insurance_policy.mandate_code_id,
                      "code"
                    )
                  : null,
                item.referring_at,
                item.number,
              ])
            }}
            <!-- ? $store.state.auth.mandate_codes.find(
                      (e) => e.id === item.insurance_policy.mandate_code_id
                    ).code -->
          </template>
          <template #cell(insurer_code)="{ item }">
            {{
              joinValues([
                item.insurance_policy.insurer.code,
                item.insurance_policy.number,
              ])
            }}
          </template>
          <template #cell(customer)="{ item }">
            {{
              item.insurance_policy.registry
                ? item.insurance_policy.registry.status_registry.value === 1
                  ? item.insurance_policy.registry.attributables.CNAM
                  : item.insurance_policy.registry.attributables.SURN +
                    " " +
                    item.insurance_policy.registry.attributables.NAME
                : ""
            }}
          </template>
          <template #cell(counterpart)="{ item }">
            <span
              v-for="(claim_party, index) in item.claim_parties"
              :key="index"
            >
              {{
                claim_party.status_claimparty.value === 1
                  ? `${claim_party.attributables.FULN} `
                  : ""
              }}
            </span>
          </template>
          <template #cell(inspector)="{ item }">
            <span
              v-for="(claim_party, index) in item.claim_parties"
              :key="index"
            >
              {{
                claim_party.status_claimparty.value === 5
                  ? `${claim_party.attributables.FULN} `
                  : ""
              }}
            </span>
          </template>
          <template #cell(surveyor)="{ item }">
            <span
              v-for="(claim_party, index) in item.claim_parties"
              :key="index"
            >
              {{
                claim_party.status_claimparty.value === 2
                  ? `${claim_party.attributables.FULN} `
                  : ""
              }}
            </span>
          </template>
          <template #cell(registry_group)="{ item }">
            {{
                item.insurance_policy.registry
                  ? item.insurance_policy.registry.registry_groups.length && item.insurance_policy.registry.registry_groups.find(
                    registry_group => registry_group.pivot.primary.value === 'Y'
                  ) ? item.insurance_policy.registry.registry_groups.find(
                    registry_group => registry_group.pivot.primary.value === 'Y'
                  ).code: ""
                  : "",
            }}
          </template>
          <template #cell(broker)="{ item }">
            <span
              v-html="toInfoData(item.insurance_policy.broker, 'broker', 0)"
            ></span>
          </template>
          <template #cell(note_counter)="{ item }" v-if="items.length">
            <div
              :role="
                item.tasks.filter((task) => task.task_type.value === 2).length
                  ? 'button'
                  : null
              "
              class="info-box float-left"
              @click="
                item.tasks.filter((task) => task.task_type.value === 2).length
                  ? openNoteDetailModal(
                      item.tasks.filter((task) => task.task_type.value === 2)
                    )
                  : null
              "
              v-b-tooltip="
                toTooltipTask(
                  item.tasks.filter((task) => task.task_type.value === 2),
                  'Note',
                  [
                    { label: 'Titolo', key: 'title' },
                    { label: 'Descrizione', key: 'description' },
                    {
                      label: 'Ultimo aggiornamento',
                      key: 'last_update',
                      formatter: (val) => {
                        return moment(val).format('DD/MM/YYYY HH:mm:ss');
                      },
                    },
                  ]
                )
              "
            >
              {{
                item.tasks.filter((task) => task.task_type.value === 2).length
              }}
            </div>
          </template>
          <template #cell(document_counter)="{ item }" v-if="items.length">
            <div
              :role="item.documents.length ? 'button' : null"
              class="info-box float-left"
              @click="
                item.documents.length
                  ? openDocumentDetailModal(item.documents)
                  : null
              "
              v-b-tooltip="
                toTooltipTask(item.documents, 'Documenti', [
                  { label: 'Titolo', key: 'title' },
                  { label: 'Descrizione', key: 'note' },
                  {
                    label: 'Ultimo aggiornamento',
                    key: 'last_update',
                    formatter: (val) => {
                      return moment(val).format('DD/MM/YYYY HH:mm:ss');
                    },
                  },
                ])
              "
            >
              {{ item.documents.length }}
            </div>
          </template>
          <template #cell(insurer)="{ item }">
            {{
              item.insurance_policy
                ? item.insurance_policy.insurer
                  ? item.insurance_policy.insurer.formatted_title
                  : ""
                : ""
            }}
          </template>
          <template #cell(actions)="row">
            <div class="action-buttons">
              <b-button
                v-if="actions.includes('infomodal')"
                size="sm"
                variant="lisaweb"
                @click="openModal(row.item, row.index, $event.target)"
                class="mt-1 mr-1"
                title="Modale Dettagli"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>

              <b-button
                v-if="actions.includes('details')"
                size="sm"
                variant="lisaweb"
                @click="row.toggleDetails"
                class="mt-1 mr-1"
                :title="
                  row.detailsShowing ? 'Chiudi dettagli' : 'Apri dettagli'
                "
              >
                <b-icon v-if="row.detailsShowing" icon="chevron-up"></b-icon>
                <b-icon v-else icon="chevron-down"></b-icon>
              </b-button>

              <b-button
                v-if="actions.includes('edit')"
                size="sm"
                variant="lisaweb"
                @click="onEdit(row.item.id, row.item)"
                class="mt-1 mr-1"
                title="Modifica"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>

              <b-button
                v-if="actions.includes('destroy')"
                size="sm"
                variant="lisaweb"
                @click="onDestroy(row.item.id)"
                class="mt-1 mr-1"
                title="Elimina"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>
          <template #row-details="row">
            <b-card>
              <b-row
                class="mb-2"
                v-for="(value, key) in customized(row.item)"
                :key="key"
              >
                <b-col sm="3" class="text-sm-right"
                  ><b>{{ key | capitalize }}:</b></b-col
                >
                <b-col>{{ value }}</b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
      </div>
      <b-row>
        <div class="show-text">
          <p>Mostra</p>
        </div>
        <div class="group">
          <b-form-group
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md=""
            label-cols-lg="3"
            label-size="sm"
            md="auto"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 68px"
              size="sm"
              @change="onPageChange(1)"
              :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>
        <div class="pagination" v-if="totalItems">
          <b-pagination
            v-model="currentPage"
            limit="3"
            :total-rows="totalItems"
            :per-page="perPage"
            size="sm"
            @change="onPageChange"
          ></b-pagination>
        </div>
        <div class="input_pagination" v-if="totalItems && !noInputPaginator">
          <base-input-pagination
            @pagination="onInputPagination"
          ></base-input-pagination>
        </div>
      </b-row>
      <b-modal
        :id="infomodalName"
        @hidden="onModalHidden"
        :title="modalTitle"
        size="xl"
        scrollable
        ok-only
        ok-variant="lisaweb"
        header-bg-variant="lisaweb"
        no-close-on-backdrop
      >
        <div>
          <b-tabs content-class="pt-1" lazy v-model="tabIndex">
            <b-tab
              v-for="(tab, index) in tabs"
              :key="index"
              @click="setTab(tab, index)"
              :title="tab.text"
              :active="index === tabIndex"
              title-item-class="lisaweb"
            >
              <button-group-tab> </button-group-tab>
              <keep-alive>
                <component
                  v-if="selected === tab.name"
                  :is="selected"
                  :resourceId="modalResourceId"
                  @fetch="fetch"
                ></component>
              </keep-alive>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import template from "./template";
import BaseIcon from "@/components/BaseIcon";
import BaseInputPagination from "@/components/form/BaseInputPagination";
import ButtonGroupTab from "@/components/ButtonGroupTab";
import General from "@/components/wallet/claims/details/General";
import Registry from "@/components/wallet/claims/details/Registry";
import Policy from "@/components/wallet/claims/details/Policy";
import Surveyor from "@/components/wallet/claims/details/Surveyor";
import Counterparties from "@/components/wallet/claims/details/Counterparties";
import Garage from "@/components/wallet/claims/details/Garage";
import Notes from "@/components/wallet/claims/details/Notes";
import Todo from "@/components/wallet/claims/details/Todo";
import Driver from "@/components/wallet/claims/details/Driver";
import Appointments from "@/components/wallet/claims/details/Appointments";
import Documents from "@/components/wallet/claims/details/Documents";
import { toLocaleCurrency } from "@/utils/strings";
import moment from "moment";
moment.locale("it");

export default {
  name: "ClaimsTable",
  extends: template,
  data() {
    return {
      tabs: [
        { name: "General", text: "Generale" },
        { name: "Registry", text: "Anagrafica" },
        { name: "Policy", text: "Polizza" },
        { name: "Surveyor", text: "Perito" },
        { name: "Counterparties", text: "Controparti" },
        { name: "Driver", text: "Conducente" },
        { name: "Garage", text: "Officina" },
        { name: "Appointments", text: "Appuntamenti" },
        { name: "Notes", text: "Note" },
        { name: "Todo", text: "Attività" },
        { name: "Documents", text: "Doc" },
      ],
      selected: "General",
      modalResourceId: null,
      tabIndex: 0,
    };
  },
  components: {
    BaseIcon,
    BaseInputPagination,
    General,
    Registry,
    Policy,
    Surveyor,
    Counterparties,
    Driver,
    Notes,
    Documents,
    ButtonGroupTab,
    Todo,
    Appointments,
    Garage,
  },
  methods: {
    openNoteDetailModal(array) {
      this.$emit("open_note", array);
    },
    openDocumentDetailModal(array) {
      this.$emit("open_document", array);
    },
    customized(item) {
      let c = {};
      c.data_accadimento_sinistro = moment(item.occurred_at).format(
        "DD/MM/YYYY"
      );
      c.data_ultima_liquidazione_parziale = moment(
        item.partially_settled_at
      ).format("DD/MM/YYYY");
      c.totale_liquidato = toLocaleCurrency(item.settled_total);
      c.franchigia = toLocaleCurrency(item.deductible);
      c.ammontare_in_riserva = toLocaleCurrency(item.amount_in_reserve);
      let customAttributes = this.convertFromCustomAttributesToFields(
        this.customAttributes()(this.repository)
      );
      Object.keys(customAttributes).forEach((i) => {
        let value = customAttributes[i].key.split(".");
        let label = customAttributes[i].label;
        c[label] = item.attributables[value[value.length - 1]];
      });
      return c;
    },
  },
};
</script>
