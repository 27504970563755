<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
          class="btn-create"
          type="button"
          variant="primary"
          size="sm"
          @click="onAdd"
          title="Crea"
          v-if="canVerb(resource, 'store')"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea</b-button
        >
      </b-col>
    </b-row>
    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-select
                name="insurer_id"
                label="Compagnia"
                :options="companies"
                v-model="filter.byInsurer.id"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="salesman_id"
                label="Produttore"
                :options="salesmen"
                v-model="filter.byBroker.id"
              />
            </div>
            <div class="col-md-3">
              <input
                type="hidden"
                name="claim_number"
                :value="filter.byAttribute.number"
              />
              <base-input
                name="clean_claim_number"
                type="text"
                label="Numero Sinistro"
                :value="decodedInputVal"
                placeholder="Inserisci un sinsitro"
                @keyup="onInputEncodeValue"
              />
            </div>
            <div class="col-md-3">
              <base-input
                name="policy_number"
                type="text"
                label="Numero Polizza"
                v-model="filter.byInsurancePolicy.number"
                placeholder="Inserisci una polizza"
              />
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3">
              <div>
                <b-button-toolbar>
                  <b-button-group>
                    <base-input
                      name="Anagrafica"
                      label="Anagrafica"
                      v-model="form.inpt_label_registry"
                      :readonly="true"
                    />
                    <b-button
                      class="mt-4 btn-quick"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      title="Ricerca Veloce"
                      @click="openQuickSearchRegistry"
                      ><b-icon icon="search"></b-icon
                    ></b-button>
                    <b-button
                      v-if="form.inpt_label_registry"
                      class="mt-4 btn-quick ml-1"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      @click="resetRegistryId"
                      title="Elimina Anagrafica"
                      ><b-icon icon="trash"></b-icon>
                    </b-button>
                  </b-button-group>
                </b-button-toolbar>
              </div>
            </div>
            <div class="form-group col-md-3">
              <span
                v-if="registry_data"
                class="info"
                v-html="toInfoData(registry_data, 'registry')"
              >
              </span>
            </div>
            <quick-search-registry-modal
              ref="quickSearchRegistry"
              @input="handleRegistryModalInput"
            >
            </quick-search-registry-modal>
          </b-row>
          <b-row>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div></b-row
          >
          <b-button-group
            v-b-toggle:collapse-1-inner
            class="my-2 filter-button-group"
          >
            <span class="when-open">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            <span class="when-closed">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            Altri Filtri
          </b-button-group>
          <b-collapse id="collapse-1-inner" class="mt-2">
            <b-row>
              <div class="col-md-3">
                <base-input
                  name="place"
                  type="text"
                  label="Luogo"
                  v-model="filter.byAttribute.place"
                  placeholder="Inserisci un luogo"
                />
              </div>
              <div class="form-group col-md-3">
                <base-datepicker
                  name="occured_at"
                  label="Data Accadimento Sinistro"
                  v-model="filter.byAttribute.occured_at"
                />
              </div>
            </b-row>
            <b-row>
              <div class="col-md-3">
                <base-input
                  name="risks"
                  label="Ramo sinistro"
                  :options="risk_branches"
                  v-model="filter.byAttribute.insurer_risk_branch"
                />
              </div>
            </b-row>
            <b-row>
              <div class="col-md-3">
                <base-select
                  name="risks"
                  label="Tipo"
                  :options="risks"
                  v-model="filter.byRiskType.id"
                  :taggable="true"
                  :multiple="true"
                  :closeOnSelect="false"
                  @input="onInputRisk"
                />
                <!-- <b-button
                  type="button"
                  variant="light"
                  size="sm"
                  @click="selectAllRisks()"
                  >Seleziona tutti</b-button
                >

                <b-button
                  type="button"
                  variant="light"
                  size="sm"
                  @click="deselectAllRisks()"
                  >Deseleziona tutti</b-button
                > -->
                <b-button
                  :pressed.sync="myToggleR"
                  variant="light"
                  class="mb-2"
                  @click="myToggleR ? selectAllRisks() : deselectAllRisks()"
                  >{{
                    myToggleR ? "Deseleziona tutti" : "Seleziona tutti"
                  }}</b-button
                >
              </div>
              <div class="col-md-3">
                <base-select
                  name="risk_branches"
                  label="Ramo Polizza"
                  :options="risk_branches"
                  v-model="filter.byRiskBranch.id"
                  :multiple="true"
                  :taggable="true"
                  :closeOnSelect="false"
                  @input="onInputBranch"
                />

                <!-- <b-button
                  type="button"
                  variant="light"
                  size="sm"
                  @click="selectAllBranches()"
                  >Seleziona tutti</b-button
                >

                <b-button
                  type="button"
                  variant="light"
                  size="sm"
                  @click="deselectAllBranches()"
                  >Deseleziona tutti</b-button
                > -->
                <b-button
                  :pressed.sync="myToggleB"
                  variant="light"
                  class="mb-2"
                  @click="
                    myToggleB ? selectAllBranches() : deselectAllBranches()
                  "
                  >{{
                    myToggleB ? "Deseleziona tutti" : "Seleziona tutti"
                  }}</b-button
                >
              </div>
              <div class="col-md-3">
                <base-select
                  name="prdct"
                  label="Prodotto"
                  :options="prdct"
                  v-model="filter.byInsuranceRisk.id"
                  :taggable="true"
                  :multiple="true"
                  :closeOnSelect="false"
                />
                <!-- <b-button
                  type="button"
                  variant="light"
                  size="sm"
                  @click="selectAllProducts()"
                  >Seleziona tutti</b-button
                >

                <b-button
                  type="button"
                  variant="light"
                  size="sm"
                  @click="deselectAllProducts()"
                  >Deseleziona tutti</b-button
                > -->
                <b-button
                  :pressed.sync="myToggleP"
                  variant="light"
                  class="mb-2"
                  @click="
                    myToggleP ? selectAllProducts() : deselectAllProducts()
                  "
                  >{{
                    myToggleP ? "Deseleziona tutti" : "Seleziona tutti"
                  }}</b-button
                >
              </div>
            </b-row>
            <b-row>
              <div class="col-md-3">
                <base-input
                  name="counterpart"
                  type="text"
                  label="Nominativo controparte"
                  @keyup="onEnterClaimParty"
                  v-model="counterpart"
                  placeholder="Inserisci una controparte"
                />
              </div>
              <div class="col-md-3">
                <base-input
                  name="referring_at"
                  type="number"
                  label="Anno"
                  v-model="filter.byAttribute.referring_at"
                  placeholder="Inserisci anno di rubricazione"
                />
              </div>
              <div class="col-md-3">
                <base-input
                  name="REPL"
                  type="text"
                  label="Targa Veicolo"
                  v-model="filter.byInsurancePolicy.REPL"
                  placeholder="Inserisci una targa"
                />
              </div>
              <div class="col-md-3">
                <base-select
                  name="settled"
                  label="Definiti/Non Definiti"
                  :options="settled_options"
                  v-model="filter.bySettled"
                />
              </div>
              <div class="col-md-3">
                <base-input
                  name="surveyor"
                  type="text"
                  label="Perito"
                  @keyup="onEnterClaimParty"
                  v-model="surveyor"
                  placeholder="Inserisci un perito"
                />
              </div>
              <div class="col-md-3">
                <base-input
                  name="surveyor"
                  type="text"
                  label="Ispettorato"
                  @keyup="onEnterClaimParty"
                  v-model="inspector"
                  placeholder="Inserisci un ispettorato"
                />
              </div>
              <div class="col-md-3">
                <base-input
                  name="registry_group"
                  type="text"
                  label="Gruppo Anagrafico"
                  v-model="filter.byRegistryGroup.code"
                  placeholder="Inserisci un gruppo"
                />
              </div>
              <div class="col-md-3">
                <base-input
                  name="CPRGP"
                  type="text"
                  label="Targa Controparte"
                  v-model="CPRGP"
                  @keyup="onEnterClaimParty"
                  placeholder="Inserisci una targa"
                />
              </div>
            </b-row>
            <div v-show="customFilters.length">
              <p>Attributi personalizzati</p>
              <b-row>
                <custom-filter
                  v-for="(element, index) in customFilters.filter(
                    (e) => getInputType(e) === 'text'
                  )"
                  :key="`A${index}`"
                  v-model="filter.byCustom[element.key]['value']"
                  :element="element"
                />
                <custom-filter
                  v-for="(element, index) in customFilters.filter(
                    (e) => getInputType(e) !== 'text'
                  )"
                  :key="`B${index}`"
                  v-model="filter.byAttribute[element.key]"
                  :element="element"
                />
              </b-row>
            </div>
          </b-collapse>
        </b-card>
      </b-form>
    </b-collapse>
    <export-options
      :exportUrl="exportUrl"
      :repository="repository"
      :resource="resource"
      :tableRef="$refs[tableRef]"
      :filter="filter"
      :options="[
        {
          code: 'EXPOCLAI',
          label: null,
          formats: ['csv', 'pdf'],
        },
      ]"
    ></export-options>
    <!-- <button-group-table
      @csv="onExport('csv')"
      :options="{
        CSV: { enabled: true },
      }"
    ></button-group-table> -->

    <claims
      @edit="onEdit"
      @destroy="onDestroy"
      @open_note="openNoteModal"
      @open_document="openDocumentModal"
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :filterOn="{
        byRelations: [
          'byCorrespondence',
          'byClaimParty',
          'byBroker',
          'byRegistryGroup',
          'byTask',
          'byDocument',
        ],
      }"
      :onlyActions="['edit', 'destroy', 'infomodal']"
      :ref="tableRef"
    ></claims>
    <note-detail-modal :items="notes"> </note-detail-modal>
    <document-detail-modal :items="documents"> </document-detail-modal>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
// import ButtonGroupTable from "@/components/ButtonGroupTable";
import ExportOptions from "@/components/ExportOptions";
import Claims from "@/components/tables/Claims";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import CustomFilter from "@/components/form/CustomFilter";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
// import ExportsMixin from "@/mixins/ExportsMixin";
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";
import { getInputType } from "@/utils/forms";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { mapGetters, mapActions } from "vuex";
import { toLocaleCurrency } from "@/utils/strings";
import { toInfoData, joinValues, findAndGet } from "@/utils/transforms";
import { toLocaleDate } from "@/utils/dates";
import NoteDetailModal from "@/components/modals/noteDetailModal";
import DocumentDetailModal from "@/components/modals/documentDetailModal";
import moment from "moment";
moment.locale("it");

export default {
  mixins: [CustomFiltersMixin, StorageGetterMixin],
  data() {
    return {
      exportUrl: null,
      myToggleR: false,
      myToggleB: false,
      myToggleP: false,
      repository: "claim",
      resource: "claims",
      filterName: "filterClaim",
      filter: this.initFilter(),
      items: [],
      companies: [],
      salesmen: [],
      notes: [],
      documents: [],
      risk_branches: [],
      risks: [],
      products: [],
      insurance_policy_data: null,
      registry_data: null,
      counterpart: null,
      surveyor: null,
      inspector: null,
      CPRGP: null,
      settled_options: [
        { value: "yes", text: "Si" },
        { value: "no", text: "No" },
      ],
      form: {
        inpt_label_registry: "",
      },
      tableRef: "ClaimTableRef",
      fields: [
        {
          label: this.getDictionary("number", "claim"),
          key: "claim_number",
          value: (item) =>
            this.joinValues([
              item.insurance_policy && item.insurance_policy.insurer
                ? item.insurance_policy.insurer.code
                : null,
              item.insurance_policy && item.insurance_policy.insurer
                ? // ? this.$store.state.auth.mandate_codes.find(
                  //     (e) => e.id === item.insurance_policy.mandate_code_id
                  //   ).code
                  this.findAndGet(
                    this.$store.state.auth.mandate_codes,
                    "id",
                    item.insurance_policy.mandate_code_id,
                    "code"
                  )
                : null,
              item.referring_at,
              item.number,
            ]),
          sortable: true,
          sortKey: "number",
        },
        {
          key: "referring_at",
          value: "referring_at",
          label: this.getDictionary("referring_at", "claim"),
          sortable: true,
          sortKey: "referring_at",
        },
        {
          key: "occurred_at",
          value: "occurred_at",
          formatter: (value) => toLocaleDate(value),
          label: this.getDictionary("occurred_at", "claim"),
          sortable: true,
          sortKey: "occurred_at",
        },
        {
          key: "dcpd_module_type.text",
          label: this.getDictionary("dcpd_module_type", "claim"),
          sortable: true,
          sortKey: "dcpd_module_type",
        },
        {
          key: "insurer_code",
          label: this.getDictionary("number", "insurance_policy"),
          sortable: true,
          sortKey: "byInsurancePolicy.number",
        },
        {
          key: "insurance_policy.insurance_risk.risk_branch.title",
          value: "insurance_policy.insurance_risk.risk_branch.title",
          label: this.getDictionary("risk_branch", "claim"),
          sortable: true,
          sortKey: "byRiskBranch.title",
        },
        {
          key: "insurance_policy.insurance_risk.title",
          value: "insurance_policy.insurance_risk.title",
          label: this.getDictionary("insurance_risk"),
          sortable: true,
          sortKey: "byInsuranceRisk.title",
        },
        {
          key: "customer",
          label: this.getDictionary("contractor"),
          sortable: true,
          sortKey: "byRegistry.title",
        },
        {
          key: "counterpart",
          label: this.getDictionary("counterpart", "claim"),
          sortable: true,
          sortKey: "byClaimParty.FULN",
        },
        {
          key: "registry_group",
          label: this.getDictionary("registry_group"),
          sortable: true,
          sortKey: "byRegistryGroup.code",
        },
        {
          key: "broker",
          value: (item) => toInfoData(item.insurance_policy.broker, "broker"),
          label: this.getDictionary("salesman"),
          sortable: true,
          sortKey: "byBroker.BUID",
        },
        {
          key: "settlement_expense",
          value: (item) => toLocaleCurrency(item.settlement_expense),
          label: this.getDictionary("settlement_expense", "claim"),
          sortable: true,
          sortKey: "byAttribute.settlement_expense",
          formatter: (value) => toLocaleCurrency(value),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "settled_total",
          value: (item) => toLocaleCurrency(item.settled_total),
          label: this.getDictionary("settled_total", "claim"),
          sortable: true,
          sortKey: "byAttribute.settled_total",
          formatter: (value) => toLocaleCurrency(value),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "surveyor",
          label: this.getDictionary("surveyor", "claim"),
          sortable: true,
          sortKey: "byClaimParty.FULN",
        },
        {
          key: "inspector",
          label: this.getDictionary("inspector", "claim"),
          sortable: true,
          sortKey: "byClaimParty.FULN",
          tdClass: "text-center",
        },
        {
          key: "document_counter",
          label: this.getDictionary("Documenti"),
          value: (item) => (item.documents.length ? item.documents.length : 0),
        },
        {
          key: "note_counter",
          label: this.getDictionary("Note"),
          value: (item) =>
            item.tasks.length
              ? item.tasks.filter((task) => task.task_type.value === 2).length
              : 0,
          sortable: false,
        },
      ],
    };
  },
  components: {
    BaseInput,
    Claims,
    // ButtonGroupTable,
    ExportOptions,
    BaseSelect,
    BaseDatepicker,
    CustomFilter,
    NoteDetailModal,
    DocumentDetailModal,
    QuickSearchRegistryModal,
  },
  methods: {
    toInfoData,
    joinValues,
    findAndGet,
    getInputType,
    initFilter() {
      let init = {
        byAttribute: {
          id: null,
          number: null,
          place: null,
          occured_at: null,
          insurer_risk_branch: null,
          referring_at: null,
        },
        byClaimParty: {
          title: null,
          status_claimparty: null,
          CPRGP: null,
        },
        bySettled: null,
        byInsurer: {
          id: null,
        },
        byInsuranceRisk: {
          id: null,
        },
        byInsurancePolicyRiskBranch: {
          id: null,
        },
        byRegistry: {
          id: null,
        },
        byRegistryGroup: {
          code: null,
        },
        byRiskBranch: {
          id: null,
        },
        byRiskType: {
          id: null,
        },
        byInsurancePolicy: {
          number: null,
          id: null,
        },
        byBroker: {
          id: null,
        },
      };
      return init;
    },
    onEnterClaimParty() {
      let claimPartyStatuses = [];
      let claimPartyTitles = [];
      if (this.surveyor) {
        claimPartyTitles.push(this.surveyor);
        claimPartyStatuses.push(2);
      }
      if (this.counterpart) {
        claimPartyTitles.push(this.counterpart);
        claimPartyStatuses.push(1);
      }
      if (this.inspector) {
        claimPartyTitles.push(this.inspector);
        claimPartyStatuses.push(5);
      }
      if (this.CPRGP) {
        this.filter.byClaimParty.CPRGP = this.CPRGP;
        if (!this.counterpart) {
          claimPartyStatuses.push(1);
        }
      }
      this.filter.byClaimParty.title = claimPartyTitles.join(",");
      this.filter.byClaimParty.status = claimPartyStatuses.join(",");
    },
    openNoteModal(array) {
      this.notes = array;
      this.$bvModal.show("noteDetailModal");
    },
    openDocumentModal(array) {
      this.documents = array;
      this.$bvModal.show("documentDetailModal");
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onEdit(id, item) {
      this.$router.push({
        name: `${this.resource}.edit`,
        params: { id: `${id}`, item: item },
      });
    },
    onAdd() {
      this.$router.push({ name: `${this.resource}.create` });
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          this.$refs[this.tableRef].fetch();
          this.$showSnackbar({
            preset: "success",
            text: "Sinistro Eliminato con successo",
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.exportUrl = this.$refs[this.tableRef].loadFilter(
        this.$refs[this.tableRef].filterName
      );
      // quicksearch
      criteria = {};
      [/*"quickSearchPolicy"*/ "quickSearchRegistry"].forEach((element) => {
        criteria[element] = {
          label: "",
          formLabel: "",
          data: null,
          formData: null,
        };
      });
      // criteria.quickSearchPolicy.label = this.form.inpt_label_policy;
      // criteria.quickSearchPolicy.formLabel = "form.inpt_label_policy";
      // criteria.quickSearchPolicy.data = this.insurance_policy_data;
      // criteria.quickSearchPolicy.formData = "insurance_policy_data";
      criteria.quickSearchRegistry.label = this.form.inpt_label_registry;
      criteria.quickSearchRegistry.formLabel = "form.inpt_label_registry";
      criteria.quickSearchRegistry.data = this.registry_data;
      criteria.quickSearchRegistry.formData = "registry_data";
      this.saveQuickSearchByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.myToggleR = false;
      this.myToggleB = false;
      this.myToggleP = false;
      this.counterpart = null;
      this.surveyor = null;
      this.inspector = null;
      this.CPRGP = null;
      this.resetRegistryId();
      this.resetFilter();
      // this.resetQuickSearch(); // dovrebbero bastare reset*id()
      this.resetPagination();
      this.removeFilterByName(name);
      this.removeQuickSearchByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
    },
    onInputInsurer(insurer) {
      this.filter.byInsurer.id = insurer;
      this.filter.byRiskBranch.id = null;
      this.risk_branches = this.getBranches()(insurer);
      this.filter.byInsuranceRisk.id = null;
      this.products = this.getProducts()(
        this.filter.byInsurer.id,
        this.filter.byRiskBranch.id,
        this.filter.byRiskType.id
      );
    },
    deselectAllBranches() {
      this.filter.byRiskBranch.id = [];
      this.filter.byInsuranceRisk.id = null;
      this.products = this.getProducts()(
        this.filter.byInsurer.id,
        this.filter.byRiskBranch.id,
        this.filter.byRiskType.id
      );
    },
    deselectAllRisks() {
      this.filter.byRiskType.id = [];
      this.filter.byInsuranceRisk.id = null;
      this.products = this.getProducts()(
        this.filter.byInsurer.id,
        this.filter.byRiskBranch.id,
        this.filter.byRiskType.id
      );
    },
    deselectAllProducts() {
      this.filter.byInsuranceRisk.id = [];
    },
    selectAllBranches() {
      this.filter.byRiskBranch.id = this.risk_branches.map(
        (branch) => branch.value
      );
    },
    selectAllRisks() {
      this.filter.byRiskType.id = this.risks.map((risk) => risk.value);
    },
    selectAllProducts() {
      this.filter.byInsuranceRisk.id = this.products.map(
        (product) => product.value
      );
    },
    onInputBranch(branches) {
      if (!branches.length) {
        this.filter.byRiskBranch.id = null;
      }
      this.filter.byInsuranceRisk.id = null;
      this.products = this.getProducts()(
        this.filter.byInsurer.id,
        branches,
        this.filter.byRiskType.id
      );
    },
    onInputRisk(risks) {
      if (!risks.length) {
        this.filter.byRiskType.id = null;
      }
      this.filter.byInsuranceRisk.id = null;
      this.products = this.getProducts()(
        this.filter.byInsurer.id,
        this.filter.byRiskBranch.id,
        risks
      );
    },
    openQuickSearchRegistry() {
      this.$bvModal.show("quickSearchRegistryModal");
    },
    handleRegistryModalInput(value) {
      this.filter.byRegistry.id = value.id;
      this.registry_data = value;
      this.form.inpt_label_registry = this.toInfoData(
        this.registry_data,
        "registryfullname"
      );
      this.$bvModal.hide("quickSearchRegistryModal");
    },
    resetRegistryId() {
      this.registry_data = null;
      this.form.inpt_label_registry = null;
      this.filter.byRegistry.id = null;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("quicksearch", {
      saveQuickSearchByName: "saveByName",
      removeQuickSearchByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    ...mapGetters("auth", {
      getInsurers: "insurers",
      getSalesmen: "salesmen",
      getBranches: "branches",
      getRisks: "risks",
      getProducts: "products",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
    decodedUrlParam(val) {
      return val ? decodeURI(val).replace("%", "") : null;
    },
    onInputEncodeValue(e) {
      const inputVal = e.target.value;
      this.filter.byAttribute.number = inputVal
        ? encodeURI(`%${inputVal}`)
        : null;
    },
  },
  mounted() {
    this.companies = this.getInsurers();
    this.salesmen = this.getSalesmen();
    this.risk_branches = this.getBranches()(this.filter.byInsurer.id);
    this.risks = this.getRisks();
  },
  beforeMount() {
    console.log("beforeMount index claim");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("mandate_codes"))
      // this.getResources("auth/mandate_codes");
      resources.push(
        this.$store
          .dispatch(`${path}mandate_codes`)
          .then(() => {
            console.info(
              `background data "${path}mandate_codes" fetched correctly`
            );
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
        // .finally(() => {
        //   this.unloadMsg();
        //   this.isLoading = false;
        // })
      );
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          console.log("All promises went through correctly");
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          // this.unloadMsg();
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
    // #1153 se non c'è già un filtro salvato, imposto i valori di default
    let found = this.loadFilter(this.filterName);
    if (!found) {
      this.$set(this, "filter", this.initFilter());
    } else {
      this.$set(this, "filter", found);
    }
  },
  computed: {
    decodedInputVal() {
      return this?.filter?.byAttribute?.number
        ? this.decodedUrlParam(this.filter.byAttribute.number)
        : null;
    },
    prdct() {
      return this.products;
    },
  },
};
</script>
