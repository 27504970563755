<template>
  <div class="mt-1">
    <b-card header="Recapiti" header-tag="header">
      <b-card-text>
        <b-row>
          <div class="form-group col-md-3">
            <base-input
              :name="beECForm.attribute_CELL_value.label"
              vid="attribute_CELL_value"
              :label="beECForm.attribute_CELL_value.label"
              v-model="inputVal.attribute_CELL_value"
              type="tel"
              placeholder="Inserisci un numero di cellulare"
              :disabled="disabled"
            />
          </div>
          <div class="form-group col-md-3">
            <base-input
              :name="beECForm.attribute_MAIL_value.label"
              vid="attribute_MAIL_value"
              :label="beECForm.attribute_MAIL_value.label"
              v-model="inputVal.attribute_MAIL_value"
              placeholder="Inserisci una mail"
              :rules="{ email: true }"
              :disabled="disabled"
            />
          </div>
          <div class="form-group col-md-3">
            <base-input
              :name="beECForm.attribute_PHON_value.label"
              vid="attribute_PHON_value"
              :label="beECForm.attribute_PHON_value.label"
              v-model="inputVal.attribute_PHON_value"
              type="tel"
              placeholder="Inserisci un numero di telefono"
              :disabled="disabled"
            />
          </div>
          <div class="form-group col-md-3">
            <base-input
              :name="beECForm.attribute_WEB_value.label"
              vid="attribute_WEB_value"
              :label="beECForm.attribute_WEB_value.label"
              v-model="inputVal.attribute_WEB_value"
              placeholder="Inserisci un sito web"
              :disabled="disabled"
            />
          </div>
          <div class="form-group col-md-3">
            <base-input
              :name="beECForm.attribute_PEC_value.label"
              vid="attribute_PEC_value"
              :label="beECForm.attribute_PEC_value.label"
              v-model="inputVal.attribute_PEC_value"
              placeholder="Inserisci una pec"
              :rules="{ email: true }"
              :disabled="disabled"
            />
          </div>
        </b-row>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import BaseInput from "@/components/form/BaseInput";

export default {
  data() {
    return {};
  },
  props: {
    beECForm: {
      type: Object,
      default() {
        return {};
      },
    },
    repository: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    value: null,
  },
  components: {
    BaseInput,
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
