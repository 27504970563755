import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  data() {
    return {
      GDPR: 0,
    };
  },
  computed: {
    address_book_form: {
      get() {
        return this.form[this.addrBook];
      },
    },
    correspondence_form: {
      get() {
        return this.form;
      },
    },
    data_protection_form: {
      get() {
        return this.form[this.dtPrt];
      },
    },
  },
  methods: {
    skipApiCall(form) {
      const re = /^attribute_(.+)_value/g;
      let exclude = ["NOR"];
      for (const [key, value] of Object.entries(form)) {
        if (value && key.match(re) && !exclude.includes(re.exec(key)[1])) {
          return false;
        }
      }
      return true;
    },
    storeSection(repository, id, key) {
      switch (repository) {
        case "correspondence":
          if (!this.form[repository].attribute_CITY2_value) {
            this.$delete(this.form[repository], "attribute_NOR_value");
            this.$delete(this.form[repository], "attribute_CITY2_value");
          }
          break;
        case "address_book":
          break;
        case "data_protection":
          // console.log(">>>>>>> store data_protection: ", this.form[repository]);
          // se modulo è attivo...
          if (
            // this.origin?.routeName === "module.PEASY.PRIV" ||
            this.canModule("module.PEASY")
          ) {
            // se GDPR è 0, e se l'utente imposta intermediary_consent: Y..
            if (
              this.GDPR == 0 &&
              this.form[repository].intermediary_consent === "Y"
            ) {
              // ..allora creo la relazione
              this.form[repository].registry_id = id;
              return this.store(repository);
            }
          }
          // altrimenti non creo la relazione
          console.info(
            `GDPR(${this.GDPR}) != 0 OR intermediary_consent(${this.form[repository].intermediary_consent}) != 'Y': nothing to POST`
          );
          return null;
        default:
          break;
      }
      if (!this.skipApiCall(this.form[repository])) {
        this.form[repository][key] = {
          [id]: {},
        };
        return this.store(repository);
      }
      return null;
    },
    editSection(repository, section_id, key) {
      switch (repository) {
        case "correspondence":
          if (!this.form[repository].attribute_CITY2_value) {
            this.$delete(this.form[repository], "attribute_NOR_value");
            this.$delete(this.form[repository], "attribute_CITY2_value");
          }
          if (!this.skipApiCall(this.form[repository])) {
            this.form[repository][key] = {
              [this.id]: {},
            };
            return this.update(repository, section_id);
          }
          return null;
        case "address_book":
          return this.attributableUpdate(repository, section_id);
        case "data_protection":
          // se modulo è attivo...
          if (
            // this.origin?.routeName === "module.PEASY.PRIV" ||
            this.canModule("module.PEASY")
          ) {
            // ..allora modifico la relazione
            this.form[repository].registry_id = this.id;
            return this.update(repository, section_id);
          }
          return null;
        default:
          break;
      }
      return null;
    },
    // per il momento nel forms.js, removeKey, toglie tutti i campi type: hidden
    // clearForm(repository, deletable) {
    //   let a = [
    //     "agency_contact",
    //     "agency_setup",
    //     "broker",
    //     "claim",
    //     "claim_party",
    //     "registry",
    //   ];
    //   for (const r of a) {
    //     if (r !== repository) {
    //       this.$delete(this.form[deletable], r);
    //     }
    //   }
    // },
    initEditCorrespondencesForm() {
      this.form.optHomeCountries = this.countries();
      this.form.optHomeProvinces = this.provinces()(
        this.form[this.cor].attribute_NOR_value
      );
      if (this.form[this.cor].attribute_CITY_value) {
        let homeCouncil;
        homeCouncil = this.councils()().find(
          (council) => council.value == this.form[this.cor].attribute_CITY_value
        );
        if (homeCouncil) {
          if (homeCouncil.province) {
            this.$set(
              this.form,
              "optHomeCouncils",
              this.councils()(homeCouncil.province)
            );
          }
          // this.$set(
          //   this.form,
          //   "optHomeCouncils",
          //   // this.councils()(homeCouncil.province)
          //   [{ value: homeCouncil.value, text: homeCouncil.text }]
          // );
          this.$set(this.form, "home_province", homeCouncil.province);
        } else {
          // non l'ha trovato perché is_dismissed = "Y"...
          // ma lo devo pur far vedere...
          homeCouncil = this.councils()(null, null, true).find(
            (e) => e.value == this.form[this.cor].attribute_CITY_value
          );
          if (homeCouncil.province) {
            this.$set(
              this.form,
              "optHomeCouncils",
              this.councils()(homeCouncil.province)
            );
          }
          // this.$set(this.form, "optHomeCouncils", [
          //   { value: homeCouncil.value, text: homeCouncil.text },
          // ]);
          this.form.home_province = homeCouncil.province;
        }
      }
    },
    initCreateCorrespondencesForm() {
      let found;
      this.form.optHomeCountries = this.countries();
      found = this.form.optHomeCountries.find(
        (c) => c.text == this.form.defaultCountryName
      );
      if (found) this.form[this.cor].attribute_NOR_value = found.value;
      this.form.optHomeProvinces = this.provinces()(
        this.form[this.cor].attribute_NOR_value
      );
      this.form.optHomeCouncils = [];
    },
    fetchAddressBook(relationString = "relations/byAddressBook") {
      let item;
      let found;
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.show(this.id, relationString)
        .then((response) => {
          item = response.data.data;
          if (item.address_books && item.address_books.length) {
            found = this.address_book_id = item.address_books.find(
              (address) => address.status_addressbook.value === 0
            );
            if (found) {
              this.address_book_id = found.id;
              return this.fetchEditForm(this.addrBook, this.address_book_id);
            }
          }
          // fallback: createForm
          return this.fetchCreateForm(this.addrBook);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        });

      // if (this.item.address_books && this.item.address_books.length) {
      //   found = this.address_book_id = this.item.address_books.find(
      //     (address) => address.status_addressbook.value === 0
      //   );
      //   if (found) {
      //     this.address_book_id = found.id;
      //     return this.fetchEditForm(this.addrBook, this.address_book_id);
      //   }
      // }
      // // fallback: createForm
      // return this.fetchCreateForm(this.addrBook);
    },
    fetchCorrespondences(relationString = "relations/byCorrespondence") {
      let item;
      let found;
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.show(this.id, relationString)
        .then((response) => {
          item = response.data.data;
          if (item.correspondences && item.correspondences.length) {
            found = item.correspondences.find(
              (e) => e.status_correspondence.value === 0
            );
            if (found) {
              this.correspondence_id = found.id;
              return this.fetchEditForm(this.cor, this.correspondence_id).then(
                () => {
                  this.initEditCorrespondencesForm();
                }
              );
            }
          }
          // fallback: createForm
          return this.fetchCreateForm(this.cor).then(() => {
            this.initCreateCorrespondencesForm();
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        });

      // if (this.item.correspondences && this.item.correspondences.length) {
      //   this.correspondence_id = this.item.correspondences.find(
      //     (address) => address.status_correspondence.value === 0
      //   ).id;

      //   found = this.item.correspondences.find(
      //     (e) => e.status_correspondence.value === 0
      //   );
      //   if (found) {
      //     this.correspondence_id = found.id;
      //     return this.fetchEditForm(this.cor, this.correspondence_id).then(
      //       () => {
      //         this.initEditCorrespondencesForm();
      //       }
      //     );
      //   }
      // }
      // // fallback: createForm
      // return this.fetchCreateForm(this.cor).then(() => {
      //   this.initCreateCorrespondencesForm();
      // });
    },
    fetchDataProtection(relationString = "relations/byDataProtection") {
      let item;
      // let found;g48
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.show(this.id, relationString)
        .then((response) => {
          item = response.data.data;
          // se c'è, ce n'è 1
          if (item.data_protection) {
            // found = this.data_protection_id = item.data_protection.find(
            //   (e) => e.registry_id == this.id
            // );
            // if (found) {
            // this.data_protection_id = found.id;
            // return this.fetchEditForm(this.dtPrt, this.data_protection_id);
            // }
            this.data_protection_id = item.data_protection.id;
            return this.fetchEditForm(this.dtPrt, this.data_protection_id);
          }
          // fallback: createForm
          return this.fetchCreateForm(this.dtPrt);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        });

      // if (this.item.address_books && this.item.address_books.length) {
      //   found = this.address_book_id = this.item.address_books.find(
      //     (address) => address.status_addressbook.value === 0
      //   );
      //   if (found) {
      //     this.address_book_id = found.id;
      //     return this.fetchEditForm(this.addrBook, this.address_book_id);
      //   }
      // }
      // // fallback: createForm
      // return this.fetchCreateForm(this.addrBook);
    },
    getAttributeId(attribute, repository = null, beForm = null) {
      let form;
      if (beForm === null) {
        form = this.beForm[repository ? repository : this.repository];
      } else {
        form = beForm;
      }
      return form[attribute].id;
    },
    attributableUpdate(repository, id, form = null) {
      const Repo = RepositoryFactory.get(repository);
      const re = /^attribute_(.+)_value/g;
      // se tutti gli attributi sono sbiancati..
      if (
        Object.entries(form || this.form[repository])
          .filter(([k]) => k.match(re))
          .every(([, v]) => ["", null, undefined].includes(v))
      ) {
        // ..elimino il record (i.e. tutti gli attributi)
        return Repo.destroy(id);
      } else {
        // chiamo la pivot NON passando gli attributi "blank"
        // {
        //   "attributable": {
        //     "ID": {
        //       "value": 1234
        //     }
        //   }
        // }
        let payload = {
          attributable: {},
        };
        for (const [attribute, value] of Object.entries(
          this.form[repository]
        )) {
          // se non è un attribute (attribute_ADDR_value), continue
          if (!attribute.match(re)) {
            continue;
          }
          // add to payload only "non-blank" attributes
          if (!["", null, undefined].includes(value)) {
            // devo recuperare l'id dell'attributo
            const attributeId = this.getAttributeId(attribute, repository);
            payload.attributable[attributeId] = { value };
          }
        }
        return Repo.pivot_update(id, "attribute", payload);
      }
    },
  },
};
