<template>
  <div class="mt-1 mx-3">
    <claims>
      <!-- <template v-slot:navigation>
        <go-back/>
      </template> -->
    </claims>
  </div>
</template>

<script>
import Claims from "@/components/wallet/claims/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    Claims,
  },
  created() {
    this.notifyQueue({
      text: "Crea Sinistro",
      path: "claims.create",
      level: 4,
    });
  },
};
</script>
