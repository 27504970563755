<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <b-row>
          <b-col align="right">
            <b-button
              type="button"
              variant="lisaweb"
              size="sm"
              @click="openAssociateModal"
              title="Associa conducente al sinistro"
              ><b-icon icon="person-plus" aria-hidden="true"></b-icon>
            </b-button>
            <b-button
              type="button"
              variant="primary"
              size="sm"
              @click="openCreateModal"
              title="Crea"
              v-if="canVerb(resource, 'store')"
              ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
              Crea</b-button
            >
          </b-col>
        </b-row>
        <div class="">
          <claim-parties
            noCustom
            hasChecks
            :filterOn="{
              byRelations: ['byCorrespondence', 'byAddressBook'],
            }"
            :fields="fields"
            :repository="repository"
            :resource="resource"
            :filterName="filterName"
            :ref="tableRef"
            :onlyActions="['edit']"
            @edit="openEditModal"
            noInnerWidth
          ></claim-parties>
        </div>
        <div class="">
          <b-button
            type="button"
            variant="lisaweb"
            size="sm"
            @click="onRemove"
            title="Disassocia guidatori dal sinistro"
            >Rimuovi</b-button
          >
        </div>
      </div>
      <template #overlay>
        <div class="text-center">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
    <div v-if="formLoaded">
      <create-claim-party-modal
        v-model="evt"
        :beFormCreate="beCreateForm"
        :beRulesCreate="beRules"
        @save="onCreate"
        @close="closeCreateModal"
        :title="title"
      ></create-claim-party-modal>
      <add-claim-party-modal
        :status_claimparty="status_claimparty"
        @save="addClaimParty"
        :fields="fields"
        :claim_id="resourceId"
      ></add-claim-party-modal>
      <edit-claim-party-modal
        v-model="evt"
        :beFormEdit="beEditForm"
        :beRulesEdit="beRules"
        :title="title"
        @close="closeEditModal"
        @update="onUpdate"
      ></edit-claim-party-modal>
    </div>
  </div>
</template>

<script>
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import BaseIcon from "@/components/BaseIcon";
import ClaimParties from "@/components/tables/ClaimParties";
import ClaimPartyMixin from "@/mixins/ClaimPartyMixin";
import { mapGetters } from "vuex";
import DetailTabAutofetchTableMixin from "@/mixins/DetailTabAutofetchTableMixin";

export default {
  name: "Driver",
  mixins: [FormMixin, ShowMixin, ClaimPartyMixin, DetailTabAutofetchTableMixin],
  props: {
    resourceId: Number,
  },
  components: {
    BaseIcon,
    ClaimParties,
  },
  data() {
    return {
      filter: this.initFilter(),
      repository: "claim_party",
      resource: "claim_parties",
      filterName: "filterDriver",
      tableRef: "driverTable",
      title: "conducente",
      correspondence_id: null,
      address_book_id: null,
      status_claimparty: 0,
      isLoading: false,
      formLoaded: false,
      beCreateForm: {},
      beEditForm: {},
      fields: [
        {
          key: "check",
          label: "",
          sortable: false,
          thClass: "check",
          class: "check",
        },
        {
          key: "attributables.FULN",
          label: "Nome",
          sortable: true,
          sortKey: "FULN",
        },
        {
          key: "address",
          label: "Indirizzo",
        },
        {
          key: "phone",
          label: "Telefono",
        },
        {
          key: "cell",
          label: "Cellulare",
        },
        {
          key: "email",
          label: "E-mail",
        },
        {
          key: "pec",
          label: "PEC",
        },
      ],
      form: {
        defaultCountryName: "Italia",
        home_province: null,
        optHomeCountries: [],
        optHomeProvinces: [],
        optHomeCouncils: [],
        claim_party: {
          status_claimparty: null,
          attribute_NAME_value: null,
          attribute_SURN_value: null,
          attribute_NINO_value: null,
          attribute_CNAM_value: null,
          attribute_CREG_value: null,
          attribute_DOB_value: null,
          attribute_MARS_value: null,
          attribute_PROF_value: null,
          attribute_TITL_value: null,
          attribute_SEX_value: null,
        },
        address_book: {
          status_addressbook: 0,
          attribute_CELL_value: null,
          attribute_FAX_value: null,
          attribute_MAIL_value: null,
          attribute_PEC_value: null,
          attribute_PHON_value: null,
          attribute_WEB_value: null,
          claim_party: {},
        },
        correspondence: {
          status_correspondence: 0,
          attribute_ADDR_value: null,
          attribute_CITY_value: null,
          attribute_CITY2_value: null,
          attribute_NOR_value: null,
          attribute_NAME2_value: null,
          attribute_ZIPC_value: null,
          claim_party: {},
        },
      },
    };
  },
  beforeMount() {
    const cp = this.fetchCreateForm(this.repository);
    const cr = this.fetchCreateForm(this.cor);
    const ad = this.fetchCreateForm(this.addrBook);
    Promise.all([cr, cp, ad]).then(() => {
      this.beEditForm = this.beCreateForm = this.beForm;
      this.formLoaded = true;
    });
  },
  methods: {
    initFilter() {
      let init = {
        byClaim: { id: this.resourceId },
        byAttribute: { status_claimparty: 0 },
      };
      return init;
    },
    ...mapGetters("auth", [
      "countries",
      "provinces",
      "provinceByProvinceId",
      "countries",
      "provinces",
      "councils",
    ]),
  },
};
</script>
