<template>
  <validation-provider
    :mode="vmode"
    :vid="vid"
    :name="name"
    :rules="rules"
    v-slot="{ dirty, validated, valid, errors }"
  >
    <b-form-group
      :id="`input-group-${id}`"
      :label="label"
      :label-for="`input-${id}`"
      :class="{
        asterisk:
          Object.keys(rules).includes('required') ||
          (Object.keys(rules).includes('required_if') && !valid),
      }"
    >
      <!-- :type="options.currencyDisplay === 'percent' ? 'number' : 'text'"
        :min="options.currencyDisplay === 'percent' ? 0 : null"
        :max="options.currencyDisplay === 'percent' ? 100 : null" -->
      <b-form-input
        :style="{ 'text-align': 'right' }"
        type="text"
        :id="`input-${id}`"
        :name="`input-${id}`"
        :maxlength="maxlength"
        :value="viewVal"
        :data-val="originalVal"
        @input="setValue"
        @keydown.native="onKeyDown"
        :state="getValidationState({ dirty, validated, valid, errors })"
        :aria-describedby="`input-${id}-live-feedback`"
        autocomplete="off"
        :disabled="disabled"
        :placeholder="placeholder"
        @blur="onBlur"
        @keyup="onKeyUp"
        @focus.native="onFocus"
        @change.once="onChange"
        :readonly="readonly"
      ></b-form-input>
      <b-form-invalid-feedback :id="`input-${id}-live-feedback`">{{
        errors[0]
      }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
export default {
  props: {
    readonly: {
      value: Boolean,
      default: false,
    },
    value: null,
    vid: String,
    name: String,
    label: String,
    placeholder: String,
    maxlength: {
      default: "10",
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
    options: {
      type: Object,
      default: () => {},
    },
    vmode: {
      type: String,
      default: "aggressive",
    },
    // available options
    // options: {
    //   locale: "it-IT",
    //   currency: "EUR",
    //   currencyDisplay: "symbol",
    //   valueRange: undefined,
    //   precision: undefined,
    //   hideCurrencySymbolOnFocus: true,
    //   hideGroupingSeparatorOnFocus: true,
    //   hideNegligibleDecimalDigitsOnFocus: true,
    //   autoDecimalDigits: false,
    //   exportValueAsInteger: false,
    //   autoSign: true,
    //   useGrouping: true
    // }
  },
  data() {
    return {
      isInputActive: false,
      id: null,
      prevWhich: null,
      formattedValue: "",
      // formatter: new Intl.NumberFormat(this.options.locale, {
      //   style: "currency",
      //   currency: this.options.currency,
      //   minimumFractionDigits: this.options.precision,
      //   maximumFractionDigits: this.options.precision,
      // }),
    };
  },
  mounted() {
    this.id = this._uid;
    this.viewVal = this.formatPrice(this.inputVal);
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        if (isNaN(val)) {
          val = 0;
          this.viewVal = "";
        } else {
          val = parseFloat(val.toString().replace(/,/g, "."));
        }
        this.$emit("input", val);
      },
    },
    originalVal() {
      return this.inputVal;
    },
    viewVal: {
      get() {
        return this.formattedValue;
      },
      set(val) {
        this.formattedValue = val;
      },
    },
  },
  watch: {
    value: {
      handler() {
        if (this.prevWhich === null) {
          this.viewVal = this.formatPrice(this.inputVal);
        }
      },
    },
  },
  methods: {
    onKeyDown(e) {
      console.debug("KD onKeyDown: ", e);
      let index;
      let len;
      switch (true) {
        // case e.which === 8: // backspace
        // case e.which === 46: // delete
        //   console.debug("KD backspace/delete: " + e.which);
        //   this.prevWhich = 9;
        //   // this.inputVal = 0; // NON FUNZIONA PURTROPPO
        //   this.viewVal = "";
        //   break;
        case e.which === 32:
        case e.which >= 65 && e.which <= 90:
          // è una lettera [a-z]
          console.debug("KD lettera");
          e.preventDefault();
          break;
        case e.which >= 48 && e.which <= 57: // è un digit [0-9]
        case e.which >= 96 && e.which <= 105: // numpad
          console.debug("KD numero");
          // se digito il 3° decimale, lo stronco ma solo se non ci sto arrivando dal tab:
          // con il valore "evidenziato" posso "sovrascrivere" nonostante ci siano già 2 decimali
          index =
            this.inputVal != null ? this.inputVal.toString().indexOf(".") : -1;
          // console.debug("KD input", this.inputVal.toFixed(2).indexOf("."));
          if (index !== -1) {
            len = this.inputVal
              .toString()
              .substring(index, this.inputVal.toString().length - 1).length;
            console.debug("KD #decimali:", len);
            if (
              len >= 2 &&
              e.target.selectionStart > index &&
              e.target.selectionStart == e.target.selectionEnd
              /* && this.prevWhich !== 9999 */
            ) {
              e.preventDefault();
              // if (len >= 3) {
              //   this.inputVal = parseFloat(
              //     this.value
              //       .toString()
              //       .substring(0, this.value.toString().length - (len - 1))
              //   );
              // }
            } else if (len == 1 && [48, 96].includes(this.prevWhich)) {
              // cannot type X after AB.C0 nor AB.0C
              e.preventDefault();
              return; // so, prevWhich stays the same
            } /* else if (len == 0) {
              return;
            } */
          }
          break;
        case e.which === 188:
          // è la virgola
          console.debug("KD virgola");
          // se esistono già decimali
          index =
            this.inputVal != null ? this.inputVal.toString().indexOf(".") : -1;
          if (index !== -1) {
            e.preventDefault();
          } else {
            this.inputVal = parseFloat(
              this.value.toString().replace(/,/g, ".")
            );
          }
          break;
        case e.which === 190:
          // è il punto
          console.debug("KD punto");
          // se esistono già decimali
          index =
            this.inputVal != null ? this.inputVal.toString().indexOf(".") : -1;
          if (index !== -1) {
            e.preventDefault();
          }
          break;
        case e.which === 173: // dash firefox
        case e.which === 189: // dash other browsers
        case e.which === 109: // minus numpad
          console.debug("KD segno -", this.inputVal);
          if (this.options.currencyDisplay === "percent") {
            e.preventDefault();
            break;
          }
          if (
            this.prevWhich !== 9999 &&
            this.viewVal !== null &&
            this.viewVal.indexOf("-") != -1
          ) {
            e.preventDefault();
          } else {
            this.inputVal = -0;
            this.viewVal = "";
          }
          break;
        // case e.which === 9: // tab
        //   e.target.select();
        //   break;
        default:
          console.debug("KD altro key");
      }
      this.prevWhich = e.which;
      this.$emit("keydown");
    },
    setValue(e) {
      // if (isNaN(e)) return;
      // console.debug("setValue: ", e);
      this.inputVal = parseFloat(e.toString().replace(/,/g, "."));
    },
    formatPrice(value) {
      if (typeof value !== "number") {
        // WORKAROUND per WHT, FCPR che sono float ma arrivano nei beForm come string...
        if (isNaN(value)) {
          // ... non è nemmeno una stringa che rappresenta un numero, niente altro da fare
          return value;
        } else {
          // è una stringa che rappresenta un numero... lo converte
          value = value !== null ? parseFloat(value) : value;
        }
        // return value;
      }
      let formatter;
      switch (this.options.currencyDisplay) {
        case "hidden":
          return value;
        case "percent":
          formatter = new Intl.NumberFormat(this.options.locale, {
            style: "percent",
            minimumFractionDigits: this.options.precision,
            maximumFractionDigits: this.options.precision,
          });
          return formatter.format(value / 100);
        case "currency":
        default:
          formatter = new Intl.NumberFormat(this.options.locale, {
            style: "currency",
            currency: this.options.currency,
            minimumFractionDigits: this.options.precision,
            maximumFractionDigits: this.options.precision,
          });
          return formatter.format(value);
      }
      // return this.options.currencyDisplay !== "hidden"
      //   ? this.formatter.format(value)
      //   : value;
    },
    getValidationState({ dirty, validated, valid = null, errors = null }) {
      if (errors[0]) return false;
      // return dirty || validated ? valid : null;
      return !Object.keys(this.rules).length
        ? null
        : dirty || validated
        ? valid
        : null;
    },
    onKeyUp(e) {
      // console.log("input onKeyUp", e);
      this.$emit("keyup", e);
    },
    onBlur(e) {
      // console.debug("input onBlur", e);
      this.isInputActive = false;
      this.viewVal = this.formatPrice(this.inputVal);
      this.prevWhich = null;
      this.$emit("blur", e);
    },
    onFocus(e) {
      console.debug("input onFocus", e);
      this.isInputActive = true;
      this.prevWhich = 9999;
      // #1020 on focus must clear the formatted value
      this.viewVal = this.inputVal;
      // select all digits - non lo fa ma nelnext tick :(
      this.$nextTick(() => {
        // e.target.setSelectionRange(0, this.inputVal.toString().length);
        e.target.select();
      });
      // this.$emit("focus", e);
    },
    onChange(value) {
      console.debug("input onChange", value);
      this.$emit("change", value);
    },
  },
};
</script>
