<template>
  <b-modal
    :id="id"
    size="lg"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="people"></b-icon>
        <span>Associa {{ title }}</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel()"
      >
        &times;
      </button>
    </template>
    <!-- <template #modal-footer="{ cancel }">
      <b-form-group>
        <b-btn
          @click="
            cancelEvent();
            cancel();
          "
          size="sm"
          variant="outline-secondary"
          >Chiudi</b-btn
        >
        <b-btn @click="saveEvent()" size="sm" variant="outline-lisaweb"
          >Salva</b-btn
        >
      </b-form-group>
    </template> -->
    <b-button-group
      v-b-toggle="'collapse-2'"
      class="my-2 filter-button-group"
      :title="getDefaultFilterMessage()"
    >
      <span class="when-open">
        <b-icon icon="funnel" font-scale="1.5"></b-icon>
      </span>
      <span class="when-closed">
        <b-icon icon="funnel" font-scale="1.5"></b-icon>
      </span>
      Filtra
    </b-button-group>
    <b-collapse visible id="collapse-2">
      <div class="mt-2">
        <b-form @submit.prevent="onSearch(filterName)">
          <b-card class="filter">
            <b-row>
              <div class="col-md-3">
                <base-input
                  name="lastName"
                  v-model="filter.byAttribute.FULN"
                  type="text"
                  label="Nome Completo"
                  placeholder="Digita Nome Completo"
                />
              </div>
              <div class="col-md-3">
                <base-input
                  name="lastName"
                  v-model="filter.byAttribute.NINO"
                  type="text"
                  label="Codice Fiscale"
                  placeholder="Digita il codice fiscale"
                />
              </div>
              <div class="col-md-3">
                <base-input
                  name="businessName"
                  v-model="filter.byAttribute.CREG"
                  type="text"
                  label="Partita IVA"
                  placeholder="Digita la Partita IVA"
                />
              </div>
              <div class="form-group col-md-4 align-self-end">
                <b-button type="submit" variant="lisaweb" size="sm"
                  >Cerca</b-button
                >
                <b-button
                  class="btn-reset"
                  type="button"
                  variant="lisaweb"
                  size="sm"
                  @click="onClearFilter(filterName)"
                  >Reset</b-button
                >
              </div>
            </b-row>
          </b-card>
        </b-form>
      </div>
    </b-collapse>
    <claim-parties
      hasChecks
      noActions
      noCustom
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      noInnerWidth
    ></claim-parties>
    <b-button
      type="button"
      @click="saveEvent()"
      variant="outline-lisaweb"
      size="sm"
      class="mt-2 float-right"
    >
      Salva
    </b-button>
  </b-modal>
</template>
<script>
import ClaimParties from "@/components/tables/ClaimParties";
import BaseInput from "@/components/form/BaseInput";
import { mapActions } from "vuex";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      repository: "claim_party",
      resource: "claim_parties",
      filterName: "filterAddClaimParty",
      tableRef: "ClaimPartyAddTableRef",
      filter: this.initFilter(),
      fields: [
        {
          key: "check",
          label: "Associa",
          sortable: false,
          thClass: "check",
          class: "check",
        },
        {
          key: "attributables.FULN",
          label: "Nome",
          sortable: true,
          sortKey: "attributables.FULN",
        },
        {
          key: "attributables.CREG",
          label: "Partita IVA",
          sortable: true,
          sortKey: "attributables.CREG",
        },
        {
          key: "attributables.NINO",
          label: "Codice Fiscale",
          sortable: true,
          sortKey: "attributables.NINO",
        },
        {
          key: "claim",
          label: "Sinistri",
          sortable: false,
        },
      ],
    };
  },
  props: {
    id: {
      type: String,
      default: "addClaimPartyModal",
    },
    status_claimparty: {
      type: Number,
    },
    claim_id: {
      type: Number,
    },
    title: {
      type: String,
    },
  },
  components: {
    BaseInput,
    ClaimParties,
  },
  methods: {
    initFilter() {
      let init = {
        byClaim: { id: `!${this.claim_id}` },
        byAttribute: {
          status_claimparty: this.status_claimparty,
          FULN: null,
          NINO: null,
          CREG: null,
        },
      };
      return init;
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch().then(() => {
        this.removeFilterByName(name);
      });
    },
    onClearFilter(name) {
      this.resetFilter();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
    },
    saveEvent() {
      this.onClearFilter();
      this.$emit("save", this.$refs[this.tableRef].selectedRows);
      this.$bvModal.hide("addClaimPartyModal");
      this.$refs[this.tableRef].selectedRows = [];
    },
    cancelEvent(selectInfo) {
      this.$emit("delete", selectInfo);
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
  },
};
</script>
<style lang="scss" scoped>
:deep(.table-responsive, .b-table-sticky-header) {
  overflow-y: auto;
}
</style>
