<template>
  <!-- DYNAMIC filter for custom element -->
  <div class="form-group col-md-3">
    <div
      v-if="
        (!getInputType(element) || ['text'].includes(getInputType(element))) &&
        element.is_searchable === 'Y'
      "
    >
      <base-input
        :name="customFilterName(element.key)"
        :vid="element.key"
        :label="element.label"
        v-model="inputVal"
        :type="getInputType(element)"
        :placeholder="'Inserisci un ' + element.label"
      />
    </div>
    <div
      v-else-if="
        ['decimal'].includes(getInputType(element)) &&
        element.is_searchable === 'Y'
      "
    >
      <base-input
        :name="customFilterName(element.key)"
        :vid="element.key"
        :label="element.label"
        v-model="inputVal"
        type="text"
        :placeholder="'Inserisci un ' + element.label"
      />
    </div>
    <div
      v-else-if="
        ['number'].includes(getInputType(element)) &&
        element.is_searchable === 'Y'
      "
    >
      <base-input
        :name="customFilterName(element.key)"
        :vid="element.key"
        :label="element.label"
        v-model="inputVal"
        :type="getInputType(element)"
        :placeholder="'Inserisci un ' + element.label"
      />
    </div>
    <div
      v-else-if="
        (!getInputType(element) ||
          ['decimal'].includes(getInputType(element))) &&
        element.is_searchable === 'Y'
      "
    >
      <base-input
        :name="customFilterName(element.key)"
        :vid="element.key"
        :label="element.label"
        v-model="inputVal"
        type="text"
        :placeholder="'Inserisci un ' + element.label"
      />
    </div>
    <div
      v-else-if="
        getInputType(element) === 'textarea' && element.is_searchable === 'Y'
      "
    >
      <base-textarea
        :name="customFilterName(element.key)"
        :vid="element.key"
        :label="element.label"
        v-model="inputVal"
        rows="4"
        maxrows="4"
      />
    </div>
    <div
      v-else-if="
        getInputType(element) === 'radio' && element.is_searchable === 'Y'
      "
    >
      <base-select
        :name="customFilterName(element.key)"
        :vid="element.key"
        :label="element.label"
        v-model="inputVal"
        :options="[
          { text: 'SI', value: 'Y' },
          { text: 'NO', value: 'N' },
        ]"
        :taggable="false"
        :multiple="false"
        :closeOnSelect="true"
      />
    </div>
    <div
      v-else-if="
        getInputType(element) === 'datepicker' && element.is_searchable === 'Y'
      "
    >
      <base-datepicker
        :name="customFilterName(element.key)"
        :vid="element.key"
        :label="element.label"
        v-model="inputVal"
      />
    </div>
    <div
      v-else-if="
        getInputType(element) === 'timepicker' && element.is_searchable === 'Y'
      "
    >
      <base-timepicker
        :name="customFilterName(element.key)"
        :vid="element.key"
        :label="element.label"
        v-model="inputVal"
      />
    </div>
  </div>
</template>
<script>
import { getInputType } from "@/utils/forms";
import BaseTextarea from "@/components/form/BaseTextarea";
import BaseSelect from "@/components/form/BaseSelect";
import BaseInput from "@/components/form/BaseInput";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseTimepicker from "@/components/form/BaseTimepicker";
export default {
  props: {
    value: undefined,
    element: Object,
  },
  components: {
    BaseTextarea,
    BaseSelect,
    BaseInput,
    BaseDatepicker,
    BaseTimepicker,
  },
  methods: {
    customFilterName(key, prefix = "attributables") {
      return `${prefix}.${key}`;
    },
    getInputType,
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
