import CreateClaimPartyModal from "@/components/modals/createClaimPartyModal";
import EditClaimPartyModal from "@/components/modals/editClaimPartyModal";
import AddClaimPartyModal from "@/components/modals/addClaimPartyModal";
import AddressBookCorrespondenceMixin from "@/mixins/AddressBookCorrespondenceMixin";
import DetailTabAutofetchTableMixin from "@/mixins/DetailTabAutofetchTableMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  mixins: [AddressBookCorrespondenceMixin, DetailTabAutofetchTableMixin],
  data() {
    return {
      id: null,
      item: null,
    };
  },
  components: {
    CreateClaimPartyModal,
    AddClaimPartyModal,
    EditClaimPartyModal,
    AddressBookCorrespondenceMixin,
  },
  computed: {
    evt: {
      get() {
        return this.form;
      },
    },
    rep() {
      return this.repository;
    },
    cor() {
      return "correspondence";
    },
    addrBook() {
      return "address_book";
    },
  },
  methods: {
    onCreate(form) {
      form[this.rep].status_claimparty = this.status_claimparty;
      this.store(this.repository)
        .then((response) => {
          let claim_party_id = response.data.data.id;
          this.setClaimPartyRelations(form, claim_party_id);
          let promises = [];
          promises.push(
            this.storeSection(this.addrBook, claim_party_id, this.repository)
          );
          promises.push(
            this.storeSection(this.cor, claim_party_id, this.repository)
          );
          Promise.all(promises)
            .then(() => {
              this.onAdd([claim_party_id])
                .then(() => {
                  this.onSearch(this.filterName);
                  this.onSucess("creato");
                  this.$bvModal.hide("createClaimPartyModal");
                  this.initDefaulValues(form);
                  this.$emit("fetch");
                })
                .catch((error) => {
                  this.onFailure(error);
                });
            })
            .catch((error) => {
              this.onFailure(error);
            });
        })
        .catch((error) => {
          this.onFailure(error);
        });
    },
    //Update
    onUpdate() {
      this.update(this.repository, this.id)
        .then(() => {
          let promises = [];
          if (this.address_book_id) {
            promises.push(
              this.editSection(
                this.addrBook,
                this.address_book_id,
                this.repository
              )
            );
          } else {
            promises.push(
              this.storeSection(this.addrBook, this.resourceId, this.repository)
            );
          }
          if (this.correspondence_id) {
            promises.push(
              this.editSection(
                this.cor,
                this.correspondence_id,
                this.repository
              )
            );
          } else {
            promises.push(
              this.storeSection(this.cor, this.resourceId, this.repository)
            );
          }
          Promise.all(promises)
            .then(() => {
              this.onSucess("modificato");
              this.onSearch(this.filterName);
              this.$bvModal.hide("editClaimPartyModal");
              this.initDefaulValues(this.form);
              this.$emit("fetch");
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
        })
        .catch((error) => {
          this.onFailure(error);
        });
    },
    setClaimPartyRelations(form, id) {
      form[this.cor].claim_party = {
        [id]: {},
      };
      form[this.addrBook].claim_party = {
        [id]: {},
      };
    },
    onSucess(action) {
      this.$showSnackbar({
        preset: "success",
        text: `Azione Completata: ${this.title}  ${action}`,
      });
    },
    onFailure(error) {
      let errMsg = this.$getErrorMessage(error);
      this.$showSnackbar({
        preset: "error",
        text: `${errMsg}`,
      });
    },
    resetFormByrepository(form, repository) {
      let exception = [
        "attribute_NOR_value",
        "status_correspondence",
        "status_addressbook",
      ];
      for (const key in form[repository]) {
        if (exception.includes(key)) {
          continue;
        }
        form[repository][key] = null;
      }
    },
    initDefaulValues(form) {
      this.resetFormByrepository(form, this.rep);
      this.resetFormByrepository(form, this.cor);
      this.resetFormByrepository(form, this.addrBook);
      form.home_province = null;
      this.form[this.cor].attribute_NOR_value = 255;
    },
    //Open, Close Modals
    closeCreateModal(form) {
      this.initDefaulValues(form);
    },
    closeEditModal(form) {
      this.initDefaulValues(form);
    },
    openAssociateModal() {
      this.$bvModal.show("addClaimPartyModal");
    },
    openCreateModal() {
      this.$bvModal.show("createClaimPartyModal");
    },
    openEditModal(id, item) {
      this.id = id;
      this.item = item;
      // console.debug("item: ", item);
      // console.debug("resource_id: ", this.resourceId);
      const found = item.claims.find((claim) => claim.id === this.resourceId);
      if (found) {
        this.isImportedClaim = found.insurance_policy.insurer.importer_id
          ? true
          : false;
      }
      this.fetchEditForm(this.repository, id).then(() => {
        let promises = [];
        promises.push(this.fetchAddressBook());
        promises.push(this.fetchCorrespondences());
        Promise.all(promises)
          .then(() => {
            this.beEditForm = this.beForm;
            this.$bvModal.show("editClaimPartyModal");
          })
          .catch((error) => {
            this.onFailure(error);
          });
      });
    },
    addClaimParty(claimPartyIds) {
      if (claimPartyIds.length) {
        return this.addToClaim(claimPartyIds)
          .then(() => {
            this.onSearch(this.filterName);
            this.$showSnackbar({
              preset: "success",
              text: `Associazione avvenuta con successo`,
            });
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
            this.isLoading = false;
          });
      }
    },
    onAdd(claimPartyIds) {
      if (claimPartyIds.length) {
        return this.addToClaim(claimPartyIds)
          .then(() => {
            this.$showSnackbar({
              preset: "success",
              text: `Associazione avvenuta con successo`,
            });
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
            this.isLoading = false;
          });
      }
    },
    addToClaim(claimPartyIds) {
      let payload = { claim_party: {} };
      claimPartyIds.forEach(
        (claimPartyId) => (payload.claim_party[claimPartyId] = {})
      );
      const Repo = RepositoryFactory.get("claim");
      return Repo.pivot_store(this.resourceId, "claim_party", payload);
    },
    onRemove() {
      let promises = [];
      if (this.$refs[this.tableRef] !== undefined) {
        let claimPartyIds = this.$refs[this.tableRef].selectedRows;
        if (claimPartyIds.length) {
          promises.push(this.removeFromClaim(claimPartyIds));
        }
      }
      if (!promises.length) {
        this.$showSnackbar({
          preset: "info",
          text: `Inserire almeno una ${this.title} `,
        });
        return;
      }
      Promise.all(promises)
        .then(() => {
          this.onSearch(this.filterName);
          this.$showSnackbar({
            preset: "success",
            text: `Eliminazione avvenuta con successo`,
          });
          this.$refs[this.tableRef].selectedRows = [];
          this.$emit("fetch");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    removeFromClaim(claimPartyIds) {
      let payload = { claim_party: {} };
      claimPartyIds.forEach(
        (claimPartyId) => (payload.claim_party[claimPartyId] = {})
      );
      const Repo = RepositoryFactory.get("claim");
      return Repo.pivot_destroy(this.resourceId, "claim_party", payload);
    },
  },
};
