<template>
  <b-modal
    :id="id"
    size="md"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="file-earmark"></b-icon>
        <span>Note</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel()"
      >
        &times;
      </button>
    </template>
    <div class="tableFixHead">
      <table>
        <thead>
          <tr>
            <th>Titolo</th>
            <th>Descrizione</th>
            <th>Pubblico</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(note, index) in items" v-bind:key="index">
            <td>{{ note.title }}</td>
            <td>{{ note.description }}</td>
            <td>{{ note.is_public.value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "noteDetail",
  data() {
    return {};
  },
  props: {
    items: Array,
    id: {
      type: String,
      default: "noteDetailModal",
    },
  },
};
</script>
<style scoped>
.tableFixHead {
  overflow: auto;
  height: 200px;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
  text-align: left;
}
th {
  background: #fff;
}

tr {
  background-color: #fff;
  border-top: 1px solid #dee2e6;
}
</style>
